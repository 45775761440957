import React from 'react';
import Navbar from '../molecules/Navbar';

export default function Page(props) {
    return (
        <React.Fragment>
            <Navbar></Navbar>
            {props.children}
        </React.Fragment>
    )
}