import React, { useState, useEffect, useContext } from 'react';

import Page from '../templates/Page';

import Filter from '../molecules/Filter'
import Auth from '../contexts/Auth';
import ModuleCard from '../molecules/ModuleCard';

import './Modules.scss';

export default function Modules(_props) {
    const [subjects, setSubjects] = useState([]);
    const { token } = useContext(Auth);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/modules`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        })
        .then(r => r.json())
        .then(r => {
            console.log(r);
            setSubjects(r.data)
        })

        return () => null;
    }, [])

    return (
        <Page>
            <Filter></Filter>
            <div className="container">
            {
                subjects.map(s => {
                    return <ModuleCard key={s.id} {...s}/>
                })
            }
            </div>
        </Page>
    )
}
