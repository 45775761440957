import React from "react";

import Star from "../atoms/Star";

export default (props) => {
    const rate = props.rate || 0;
    const onRating = props.onRating || (() => null);

    let index = -1;
    const starClick = (rating) => {
        return () => {
            return onRating(rating);
        }
    }

    const stars = Array(5).fill().map(el => {
        index++;
        return <Star onClick={starClick(index + 1)} key={index} filled={rate > index} color={props.color}/>
    })
    return (
        <div style={{display: 'flex'}}>
            {stars}
        </div>
    )
}
