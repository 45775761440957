import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import AuthContext from '../contexts/Auth';

import Page from '../templates/Page';

import MultipleChoices from "../organisms/MultipleChoices";

import QuestionBlocks from "../molecules/QuestionBlocks";

import { sizes } from "../utils/style";

const PreformattedText = styled.pre`
  white-space: pre-wrap;
  background-color: #e8e7f0;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 15px !important;

  @media (min-width: ${sizes.tablet}) {
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  flex-basis: 40%;
  display: flex;
`;

const Title = styled.h1`
  position: relative;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
  text-align: center;
  margin-top: 0.5rem;
  padding: 2.5rem 0;
`;

export default (props) => {
  const [correct, setCorrect] = useState(null);
  const [correctOption, setCorrectOption] = useState(null);
  const [selectedChoice, selectChoice]  = useState(null);
  const [choices, setChoices] = useState([]);
  const [question, setQuestion] = useState({
    step: ""
  });
  const [loading, setLoading] = useState(false);
  const [explanation, setExplanation] = useState("");

  const setOptions = (options) => {
    let index = 0;
    const mapped_options =
      options
      .map(option => {
        return {
          ...option,
          alternative: String.fromCharCode(97 + index++).toUpperCase()
        }
      })
    setChoices(mapped_options);
  }

  const authContext = useContext(AuthContext);

  const module_id = props.match.params.id;

  const answer = async () => {
    setLoading(true);
    await fetch(`${process.env.REACT_APP_API_URL}/api/exams/${module_id}/answer`,
      {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authContext.token}`
        },
        body: JSON.stringify({
          option_id: selectedChoice.id
        })
      })
      .then(r => r.json())
      .then(r => {
        setCorrect(r.correct);
        setCorrectOption(r.correct_option)
        setExplanation(r.explanation);
      })
      .catch(e => console.error(e))

    setLoading(false);
  }

  const nextQuestion = async () => {
    setLoading(true);
    await fetch(`${process.env.REACT_APP_API_URL}/api/exams/${module_id}/next_question`,
      {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authContext.token}`
        }
      })
      .then(async r => {
        const body = await r.json();
        if (!r.ok) {
          if (body.error === "No more questions in exam") {
            return props.history.push(`/modulos/${props.match.params.id}`);
          }

          return;
        }

        setQuestion(body.current_question);
        setOptions(body.current_question.options);
        setExplanation("");
        setCorrect(null);
        selectChoice(null);
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
      })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/exams/${props.match.params.id}/status`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authContext.token}`
        }
      })
      .then(async (r) => {
        if (!r.ok) {
          return;
        }

        const body = await r.json();
        setQuestion(body.current_question);
        setOptions(body.current_question.options);
        if (body.current_question_answer !== null && body.current_question_answer !== undefined) {
          setCorrect(body.current_question_answer.correct);
          setCorrectOption(body.correct_option);
          selectChoice({
            id: body.current_question_answer.option_id
          });
          setExplanation(body.explanation);
        }
      })
      .catch(e => {
        console.error(e)
      })
  }, [props.match.params.id])

  return (
    <Page>
      <div style={{display: 'flex'}}>
        <div style={{margin: 'auto 0', zIndex: '2'}} onClick={() => props.history.push(`/modulos/${props.match.params.id}`)}>
          <span className="icon is-large">
            <i className="fas fa-3x fa-angle-left" aria-hidden="true"></i>
          </span>
        </div>
        <div style={{margin: 'auto'}}>
          <Title>{question.step.toUpperCase()}</Title>
        </div>
      </div>
      <QuestionContainer className="container">
        <div style={{flexBasis: '60%'}}>
          <QuestionBlocks blocks={question.blocks}/>
          <MultipleChoices
            selectedChoice={selectedChoice}
            correct={correct}
            correctOption={correctOption}
            onChoose={(choice) => {
              if (correct === null) {
                selectChoice(choice)
              }
            }}
            choices={choices}
          />

          <div style={{display: 'flex'}}>
            {
            correct !== null ?
            <button
              style={{marginLeft: 'auto'}}
              className={`button is-link ${loading ? 'is-loading' : ''}`}
              onClick={nextQuestion}
            >
              Proxima Questão
            </button>
              :
            <button
              onClick={answer}
              style={{marginLeft: correct === null ? 'auto' : '1rem'}}
              className={`button is-link ${selectedChoice === null ? 'is-static' : ''} ${loading ? 'is-loading' : ''}`}>
                Responder
            </button>
            }
          </div>
          <PreformattedText style={{margin: '1rem 0'}}>
            {explanation}
          </PreformattedText>
        </div>
        <Sidebar>
        </Sidebar>
      </QuestionContainer>
    </Page>
  )
}
