import React from 'react';
import { Redirect } from 'react-router-dom';

import logo from '../logo.svg';
import Page from '../templates/Page';

function Home(props) {
  if (true) {
    return <Redirect to={{pathname: '/modulos', state: { from: props.location }}}/>
  }

  return (
    <Page>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </Page>
  )
}

export default Home;
