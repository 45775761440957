import React from "react";

import './FeedbackBox.scss'

export default (props) => {
    const cancel = props.cancel || (() => null);
    const send = props.send || (() => null);

    return (
        <div className={`feedback-box ${props.open ? '' : 'is-hidden'}`}>
            <div className="field">
                <label className="label">Feedback:</label>
                <div className="control">
                    <textarea
                        className={`textarea has-fixed-size ${props.loading ? 'is-loading' : '' }`}
                        placeholder="Nos conte como melhorar!"
                        cols="30"
                        rows="5"
                        value={props.value}
                        onChange={props.onChange}
                    >
                    </textarea>
                </div>
            </div>
            <div className="field is-grouped is-grouped-right">
                <div className="control">
                    <button className={`button is-text`} onClick={cancel}>Cancelar</button>
                </div>
                <div className="control">
                    <button className={`button is-link ${props.loading ? 'is-loading' : '' }`} onClick={send}>Enviar</button>
                </div>
            </div>
        </div>
    )
}
