import React, { useState } from 'react';

export default (props) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);

    const subscribeNewsletter = async (email) => {
        if (!email) {
            return;
        }

        setLoading(true);

        const r = await fetch(`${process.env.REACT_APP_API_URL}/api/newsletters`, { 
                method: 'POST',
                body: JSON.stringify({ newsletter: { email } }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .catch(e => ({ ok: false }));
        setLoading(false);
        if (r.ok) {
            setEmail('');
            setSuccess(true);
            return;
        }

        return;
    }

    return (
        <form style={{padding: '20px 40px'}} action="submit" onSubmit={(e) => {
            e.preventDefault();
            subscribeNewsletter(email);
        }}>
            <div className="control">
                <div className={`notification is-link`}>
                Estamos em período de teste fechado. Cadastre-se para receber nossas novidades:
                </div>

                {
                    success ?
                    <div className="notification is-success">
                    <button className="delete" onClick={() => setSuccess(false)}></button>
                    Cadastrado em nossa newsletter com sucesso
                    </div>
                    :
                    null
                }

                <div className="field">
                    <div className="control has-icons-left">
                        <input type="email" className="input is-medium" placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)}/>
                        <span className="icon is-small is-left"><i className="fas fa-envelope"> </i></span>
                    </div>
                </div>

                <div className="field">
                <p className="control" onClick={(e) => {
                    e.preventDefault();
                    subscribeNewsletter(email);
                }}>
                    <button className={`button is-medium is-success is-fullwidth ${loading ? 'is-loading' : ''}`}>
                    Cadastre-se
                    </button>
                </p>
                </div>
            </div>
        </form>
    )
};
