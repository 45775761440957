import React from "react";

import QuestionImage from '../atoms/QuestionImage';
import QuestionText from '../atoms/QuestionText';

const QuestionBlocks = (props) => {
  const { blocks } = props;
  if (!blocks) {
    return null;
  }

  let index = 0;
  return (
    <React.Fragment>
      {
        blocks
          .map(block => {
            if (block.type === "paragraph") {
              return <QuestionText {...block.data} key={index++}/>
            }

            if (block.type === "image") {
              return <QuestionImage {...block.data} key={index++}/>
            }

            return null
          })
      }
    </React.Fragment>
  )
}

export default QuestionBlocks;
