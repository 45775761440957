import React from "react";
import styled from "styled-components";

const PreformattedText = styled.pre`
  white-space: pre-wrap;
  background-color: #e8e7f0;
`;

const QuestionText = (props) => {
  return (
    <PreformattedText>
      {props.text}
    </PreformattedText>
  )
}

export default QuestionText;
