import React from 'react';

function Logo() {
  return (
    <a className="navbar-item" href="/">
      <img src="https://www.braaco.com/TesteSites/kinase/img/logo-kinase.svg" alt="Kinase Logo" width="128" height="32"/>
    </a>
  )
}

export default Logo;
