import React, { useContext, useState } from 'react';

import Auth from '../contexts/Auth';

export default (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const { authenticate } = useContext(Auth);

    const auth = async () => {
        if (!email || !password) {
            setError("Todos campos são obrigatórios");
            return;
        }

        setLoading(true);
        const result = await authenticate(email, password);

        if (result) {
            return;
        }

        setLoading(false);
        setError("Combinação de email e senha incorretos");
        return;
    }

    return (
        <form style={{padding: '20px 40px'}} action="submit" onSubmit={(e) => {
            e.preventDefault();
            auth(email, password);
        }}>
            <div className="control">
                <div className={`notification is-danger ${error === '' ? 'is-hidden' : ''}`}>
                <a className="delete"
                    href="#close"
                    onClick={() => setError('')}
                >Fechar notificação</a>
                {error}
                </div>

                <div className="field">
                    <div className="control has-icons-left">
                        <input type="email" className="input is-medium" placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)}/>
                        <span className="icon is-small is-left"><i className="fas fa-envelope"> </i></span>
                    </div>
                </div>

                <div className="field">
                    <div className="control has-icons-left">
                        <input type="password" className="input is-medium" placeholder="Senha" value={password} onChange={(e) => setPassword(e.currentTarget.value)}/>
                        <span className="icon is-small is-left"><i className="fas fa-lock"></i></span>
                    </div>
                </div>

                <div className="field">
                <p className="control" onClick={(e) => {
                    e.preventDefault();
                    auth(email, password);
                }}>
                    <button className={`button is-medium is-success is-fullwidth ${loading ? 'is-loading' : ''}`}>
                    Log In
                    </button>
                </p>
                </div>
            </div>
        </form>
    )
};
