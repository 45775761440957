import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../atoms/Card';
import './ModuleCard.scss'

export default (props) => {
    return (
        <div className="module-card">
            <Link to={`/modulos/${props.id}`}>
                <Card {...props}></Card>
            </Link>
        </div>
    )
};
