import React, { useState, useContext, useEffect } from 'react';

import AuthContext from '../contexts/Auth';

import Page from '../templates/Page';

import Feedback from '../organisms/Feedback';

export default (props) => {
  const [module, setModule] = useState({
    name: 'Loading...',
    description: ''
  });

  const [started, setStarted] = useState(false);
  const authContext = useContext(AuthContext);

  const start = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/exams/${props.match.params.id}/start`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authContext.token}`
        }
      }
    )
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/exams/${props.match.params.id}/status`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authContext.token}`
        }
      }
    )
    .then(async r => {
      if (!r.ok) {
        return fetch(`${process.env.REACT_APP_API_URL}/api/modules/${props.match.params.id}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authContext.token}`
          }
        })
        .then(r => r.json())
        .then(r => setModule(r.data))
        .catch(e => {
          console.error(e);
          setModule({name: 'Error loading module'});
        });
      }

      const body = await r.json();
      setStarted(true);
      setModule(body.module);
    })
    .catch(e => {
      console.error(e);
    })

  }, [props.match.params.id]);

  return (
    <Page>
      <div className="container" style={{flexDirection: 'column'}}>
        <div style={{textAlign: 'center', flexGrow: '1'}}>
          <h1 className="title">{module.name}</h1>
        </div>
        <div style={{display: 'flex', marginTop: '2rem', flexDirection: 'column'}}>
          <div style={{flexGrow: '1'}}>
            {module.description}
          </div>
          <div style={{flexGrow: '1', display: 'flex', marginTop: '1rem'}}>
            <Feedback module_id={props.match.params.id} rate={module.rate}/>
            <button
              className="button is-link is-medium"
              style={{marginLeft: 'auto'}}
              onClick={async () => {
                if (!started) {
                  await start();
                }

                return props.history.push(`/modulos/${props.match.params.id}/questoes`);
              }}
            >
              {started ? "Recomeçar estudos" : "Iniciar estudos"}
            </button>
          </div>
        </div>
      </div>
    </Page>
  )
}
