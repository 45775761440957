import React from "react";
import styled from "styled-components";

import Choice from "../molecules/Choice";

const MultipleChoices = styled.div`
    padding-top: 1rem;
`;

export default (props) => {
    const { choices, selectedChoice, correctOption, onChoose } = props;

    const choicesArr = choices.map(choice => {
        const selected = selectedChoice && choice.id === selectedChoice.id;
        let correct = props.correct
        if (correct !== null && selected === false) {
            if (correctOption === choice.id) {
                correct = true;
            } else {
                correct = null;
            }
        }

        return (
            <Choice
                key={choice.id}
                correct={correct}
                selected={selected}
                onClick={onChoose}
                choice={choice}
                />
        )
    });
    return (
        <MultipleChoices>
            <p>Alternativas:</p>
            {choicesArr}
        </MultipleChoices>
    )
}
