import React from "react";

export default (props) => {
  const filled = props.filled;
  const color = props.color === 'green' ? 'has-text-success' : 'has-text-warning';

  return (
    <span
      className={`is-icon ${filled ? color : ""}`}
      onClick={props.onClick}
      style={{margin: 'auto 0'}}
    >
      <i className="fa fa-star"></i>
    </span>
  )
}
