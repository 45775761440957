import React, { useState, useContext, useEffect, useRef } from "react";

import { useOnClickOutside } from "../utils/hooks";

import AuthContext from "../contexts/Auth";

import Rating from "../molecules/Rating";
import FeedbackBox from "../molecules/FeedbackBox";

export default (props) => {
    const [loading, setLoading] = useState(false);
    const [confirmedRate, setConfirmedRate] = useState(props.rate || 0);
    const [rate, setRate] = useState(0);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");

    const authContext = useContext(AuthContext);

    useEffect(() => {
        setConfirmedRate(props.rate)
    }, [props.rate]);

    const cancel = () => {
        setShowFeedbackForm(false);
        setRate(0);
        setFeedbackText("");
    };

    const ref = useRef();
    useOnClickOutside(ref, cancel);

    const send = async () => {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_API_URL}/api/feedbacks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authContext.token}`
            },
            body: JSON.stringify({
                feedback: {
                    question: props.question_id,
                    module: props.module_id,
                    feedback: feedbackText,
                    rate: rate
                }
            })
        })
        .catch((e) => console.error(e))

        setLoading(false);
        setConfirmedRate(rate);
        cancel();
    }

    return (
        <div style={{display: "flex", flexDirection: "column"}} ref={ref}>
            Avalie esse modulo:
            <Rating
                onRating={(rating) => {
                    setRate(rating);
                    setShowFeedbackForm(true);
                }}
                rate={rate !== 0 ? rate : confirmedRate}
                color={rate !== 0 ? "yellow" : "green"}
            />
            <div style={{position: 'relative'}}>
                <FeedbackBox
                    open={showFeedbackForm}
                    cancel={cancel}
                    send={send}
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.currentTarget.value)}
                    loading={loading}
                />
            </div>
        </div>
    )
}
