import React from "react";

import styled from "styled-components";

const color = (selected) => selected ? '#766ea5' : '#cbeb80';
// const color = (selected) => selected ? '#a4dc20' : '#cbeb80';

const Choice = styled.div`
    display: flex;
    flex-grow: 1;
    height: fit-content;
    min-height: 2rem;
    align-items: center;
    margin-bottom: 0.5rem;

    :hover {
        cursor: pointer;
    }
`;

const Alternative = styled.div`
    background-color: ${props => props.color};
    min-width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 5px;
`

const Right = styled.div`
    flex-grow: 1;
    padding-left: 0.5rem;
    background-color: ${props => props.color};
    margin-left: 1rem;
    height: 100%;
    display: flex;
    line-height: 1.5;
`;

const CenteredText = styled.div`
    margin: auto 0;
    padding: 5px;
`;

export default (props) => {
    const { onClick, choice, selected, correct } = props;
    let alternative_color = color(selected);
    if (correct === false && selected) {
        alternative_color = 'red'
    } else if (correct === true) {
        alternative_color = '#a4dc20'
    }

    return (
        <Choice onClick={() => onClick(choice)}>
            <Alternative color={alternative_color}>
                {choice.alternative.toUpperCase()}
            </Alternative>
            <Right color={alternative_color}>
                <CenteredText>{choice.text}</CenteredText>
            </Right>
        </Choice>
    )
}

