import React from "react";

const QuestionImage = (props) => {
  const { url, caption, alt } = props;

  return (
    <figure style={{textAlign: 'center'}}>
      <img src={url} alt={alt}/>
      <figcaption>{caption}</figcaption>
    </figure>
  )
}

export default QuestionImage;
