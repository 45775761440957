import React from "react";
import Plot from "react-plotly.js";

import Page from './Page';

const Result = (_props) => {
  return (
    <Page>
      <div>
        <Plot
          data={[
            {type: 'pie', labels: ['Correto', 'Incorreto'], values: [3, 1], domain: {row: 0, column: 0}},
            {type: 'pie', labels: ['Teste'], values: [1], domain: {row: 1, column: 0}}
          ]}
          layout={ {width: 640, height: 240, title: 'A Fancy Plot'} }
        />
      </div>
    </Page>
  );
};

export default Result;
