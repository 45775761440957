import React, { useContext } from 'react';
import Navbar from '../contexts/Navbar';

function Burger(props) {
  const isActive = useContext(Navbar) ? "is-active" : "";
  return (
    <a
      role="button"
      className={`navbar-burger burger ${isActive}`}
      href="#burger"
      onClick={props.toggleMenu}
      aria-label="menu"
      aria-expanded="false">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  )
}

export default Burger;
