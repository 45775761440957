import React, { useState } from 'react';

import LoginForm from '../molecules/LoginForm';
import RegisterForm from '../molecules/RegisterForm';

export default (props) => {
    const [card, setCard] = useState('login');

    return (
        <div className="card login-card" style={{margin: 'auto', borderRadius: '8px'}}>
            <div className="tabs is-medium is-fullwidth">
                <ul>
                    <li className={card === 'login' ? 'is-active' : ''} onClick={() => setCard('login')}>
                        <a href="#entrar"><span>Entrar</span></a>
                    </li>
                    <li className={card === 'register' ? 'is-active' : ''} onClick={() => setCard('register')}>
                        <a href="#registrar"><span>Registrar</span></a>
                    </li>
                </ul>
            </div>

            {
                card ===  'login' ?
                <LoginForm/>
                :
                <RegisterForm/>
            }
        </div>
    )
};
