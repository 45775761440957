import { useState, useEffect } from 'react';

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(valueToStore);

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      let touchendTimeout = null;
      const listener = event => {
        touchendTimeout = null;
        // If user clicks inside the element, return
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        // If user clicks on scrollbar, return
        if (document.documentElement.clientWidth <= event.clientX
          || document.documentElement.clientHeight <= event.clientY) {
          return;
        }

        handler(event);
      };

      const bouncedListener = (event) => {
        touchendTimeout = setTimeout(() => listener(event), 500);
        console.error('touchedTimeout', touchendTimeout);
      };

      const cancelListenerTrigger = () => {
        console.error('cancelListenerTrigger', touchendTimeout);
        if (touchendTimeout !== null) {
          clearTimeout(touchendTimeout)
        }
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', bouncedListener);
      document.addEventListener('scroll', cancelListenerTrigger);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', bouncedListener);
        document.removeEventListener('scroll', cancelListenerTrigger);
      };
    },

    [ref, handler]
  );
}
