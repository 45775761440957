import React from 'react';

import LoginRegisterCard from '../organisms/LoginRegisterCard';

import "./Login.scss";

function Login(props) {
    return (
        <div className="login-page" style={{height: '100%'}}>
            <div className="container is-fluid" style={{flexDirection: 'column'}}>
                <h1 className="logo">
                    <a href="https://kinase.med.br" title="Kinase">
                        <img src="https://www.braaco.com/TesteSites/kinase/img/logo-kinase.svg" alt="Kinase logo" width="128" height="32"/>
                    </a>
                </h1>
                <LoginRegisterCard/>
            </div>
        </div>
    )
}

export default Login;
