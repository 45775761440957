import React, { useState, useContext } from 'react';

import Logo from '../atoms/Logo';
import Burger from '../atoms/Burger';

import NavbarContext from '../contexts/Navbar';
import AuthContext from '../contexts/Auth';

function Navbar() {
  const [showMenu, toggleMenu] = useState(false);
  const { logout } = useContext(AuthContext);

  return (
    <NavbarContext.Provider value={showMenu}>
      <nav className="navbar is-primary">
        <div className="navbar-brand">
          <Logo></Logo>
          <Burger toggleMenu={() => toggleMenu(!showMenu)}></Burger>
        </div>

        <div className={`navbar-menu ${showMenu ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
              <a href="#logout" onClick={logout} className="button is-light">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </NavbarContext.Provider>
  )
}

export default Navbar;
