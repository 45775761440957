import React from 'react';

import './Card.scss';

export default (props) => {
    return (
        <div className="card">
            <div className="card-image">
                <figure className="image is-16by9"><img src={props.image} alt=""/></figure>
            </div>
            <div className="card-content">
                <div className="media">
                    <div className="media-content">
                        <p className="title is-4">{props.name}</p>
                    </div>
                </div>

                <div className="content">
                    <p>
                        {props.short_description}
                    </p>
                </div>
            </div>
        </div>
    )
};
