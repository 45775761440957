import './App.scss';

import {
    BrowserRouter as Router,
    Route,
    Redirect, Switch
} from 'react-router-dom';
import React, { useContext } from 'react';

import { useLocalStorage } from './utils/hooks';
import AuthContext from './contexts/Auth';
import Home from './pages/Home';
import Login from './pages/Login';
import ModuleDetail from './pages/ModuleDetail';
import Modules from './pages/Modules';
import Question from './pages/Question';
import Result from './templates/Result';

function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const authContext = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        authContext.user ?
        <Component {...props}/>
          :
        <Redirect to={{pathname: '/login', state: { from: props.location }}}/>
      }
    />
  )
}

function App() {
  const [state, setState] = useLocalStorage('user', { user: null, token: null });
  const logout = () => {
    setState({user: null, token: null})
  };

  const authenticate = async (email, password) => {
    const r = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: 'POST',
      mode: "cors",
      body: JSON.stringify({
        email: email,
        password: password
      }),
      headers: { "Content-Type": "application/json" }
    })
    .catch(_e => ({ ok: false }));

    if (!r.ok) {
      return false;
    }

    const result = await r.json();
    setState(result);
    return true;
  }

  return (
    <Router>
      <AuthContext.Provider value={{...state, authenticate, logout}}>
        <div className="App">
            <Switch>
                <Route exact path='/login' render={(props) => {
                  if (state.user) {
                    return <Redirect to={{pathname: '/home', state: { from: props.location }}}/>
                  }

                  return <Login/>
                }}/>
                <Route path='/result' component={Result}/>
                <PrivateRoute path='/home' component={Home}/>
                <PrivateRoute path='/modulos/:id/questoes' component={Question}/>
                <PrivateRoute path='/modulos/:id' component={ModuleDetail}/>
                <PrivateRoute path='/modulos' component={Modules}/>
                <Route render={(props) => <Redirect to={{pathname: '/login', state: { from: props.location }}}/>}/>
            </Switch>
        </div>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
